<template>
  <el-container>
    <MyHeader></MyHeader>
    <!-- <MyContent></MyContent> -->
    <router-view></router-view>
    <MyFooter></MyFooter>
    <el-footer height="0px"></el-footer>
  </el-container>
</template>

<script>
import MyFooter from './myfooter/index.vue'
import MyHeader from './myheader/index.vue'
export default {
  name: "Home",
  components:{
    MyFooter,
    MyHeader
  }
};
</script>

<style>

.el-aside {
  background-color: #d3dce6;
  color: #333;
  text-align: center;
  line-height: 200px;
}

body > .el-container {
  margin-bottom: 40px;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}
</style>