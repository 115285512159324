<template>
  <!-- <div class="tese_menu"> -->
    <el-row type="flex" justify="space-around">
      <el-col v-for="p in info" :key="p.id">
        <h3>{{ p.fenlei_name }}</h3>
        <ul>
          <a @click="to_work(i.link)" v-for="i in p.fenlei" :key="i.id">
            <li style="font-size: 16px">
              <img class="img_logo" :src="i.img" draggable="false" />
              <span>{{ i.name }}</span>
            </li>
          </a>
        </ul>
      </el-col>
    </el-row>
  <!-- </div> -->
</template>

<script>
export default {
  name: "Mymenu",
  data() {
    return {
      info: [

        {
          fenlei_name: "特色转换",
          fenlei: [
            {
              id: "30",
              name: "图片转素描",
              des: "在线将图片模拟成素描画",
              link: "img_sumiao",
              img: require("@/assets/img/img_sumiao.png"),
            },
            {
              id: "31",
              name: "图片转漫画",
              des: "在线将图片模拟成漫画",
              link: "img_dongman",
              img: require("@/assets/img/img_dongman.png"),
            },
            {
              id: "32",
              name: "长图拼接",
              des: "多张图片合成一张图片",
              link: "img_changtu",
              img: require("@/assets/img/img_changtu.png"),
            },
            {
              id: "33",
              name: "HTML转图片",
              des: "把HTML网页转为图片文件",
              link: "html_to_img",
              img: require("@/assets/img/html_to_img.png"),
            },
            {
              id: "34",
              name: "图片加水印",
              des: "给您的图片文件添加水印",
              link: "img_logo",
              img: require("@/assets/img/logo_pdf.png"),
            },
          ],
        },
      ],
    };
  },
  methods: {
    to_work(link) {
      this.$emit('menu')

      if(link=="img_changtu"){
          this.$router.push("/upload/" + link);
      }
      else if(link=="img_to_pdf"){
         this.$router.push("/upload/" + link);

      }else if(link=="merge_pdf"){
         this.$router.push("/upload/" + link);

      }
      else{
         this.$router.push("/to_work/" + link);
      }


      


    },
  },
};
</script>

<style>
.tese_menu {
   width: 50%;
  height: 100%;
  background: white;
  border-radius: 5px;
  box-shadow: 0 5px 21px -10px rgb(67 91 253 / 25%);
  padding: 38px 0px 26px 0px;
}
/* .tese_menu> .el-row {
  margin: 0px 200px;
  /* background: beige; 
} */

.tese_menu > .el-row > .el-col {
  /* background: red; */
 margin-left: 30px;
  width: 250px;
  /* padding-left: 40px; */
}

.tese_menu > * li {
  border-radius: 5px;
  padding: 0px 10px;
  height: 40px;
  line-height: 40px;
  width: 88%;
  list-style: none;
  cursor: pointer;
}

.tese_menu > * h3 {
  font-size: 14px;
  line-height: 18px;
  color: #2b388f;
  font-weight: 600;
  padding-bottom: 5px;
  margin-left: 7px;
}

.tese_menu > * a {
  text-decoration: none;
  color: #2b388f;
}

.tese_menu > * a:visited {
  color: #2b388f;
}
.tese_menu > * a:active {
  color: #fff;
}

.tese_menu > * span {
  margin-left: 5px;
  font-size: 12px;
  font-weight: 500;
}

.el-col li:hover {
  font-weight: 600;
  background: #dae0ff;
  color: #0051ff;
}

.img_logo {
  height: 20px;
}
</style>