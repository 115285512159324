<template>
  <!-- <div class="wendang_menu"> -->
    <el-row type="flex" justify="space-around">
      <el-col v-for="p in info" :key="p.id">
        <h3>{{ p.fenlei_name }}</h3>
        <ul>
          <a @click="to_work(i.link)" v-for="i in p.fenlei" :key="i.id">
            <li style="font-size: 16px">
              <img class="img_logo" :src="i.img" draggable="false" />
              <span>{{ i.name }}</span>
            </li>
          </a>
        </ul>
      </el-col>
    </el-row>
  <!-- </div> -->
</template>

<script>
export default {
  name: "Mymenu",
  data() {
    return {
      info: [
        {
          fenlei_name: "文档处理",
          fenlei: [
            {
              id: "13",
              name: "PDF加密",
              des: "用密码加密您的PDF",
              link: "lock_pdf",
              img: require("@/assets/img/lock_pdf.svg"),
            },
            {
              id: "14",
              name: "PDF解密",
              des: "删除PDF密码",
              link: "unlock_pdf",
              img: require("@/assets/img/unlock_pdf.svg"),
            },
            {
              id: "15",
              name: "PDF加水印",
              des: "给您的PDF文件添加水印",
              link: "logo_pdf",
              img: require("@/assets/img/logo_pdf.png"),
            },
            {
              id: "16",
              name: "PDF去水印",
              des: "去除您PDF文件的水印",
              link: "unlogo_pdf",
              img: require("@/assets/img/unlogo_pdf.png"),
            },
            {
              id: "17",
              name: "PDF重置密码",
              des: "重置您的PDF密码",
              link: "reset_pdf",
              img: require("@/assets/img/reset_pdf.png"),
            },

            {
              id: "28",
              name: "WORD文字替换",
              des: "给您的WORD文档替换指定的文字",
              link: "word_str_replace",
              img: require("@/assets/img/word_str_replace.png"),
            },
            {
              id: "29",
              name: "WORD文字删除",
              des: "查找您的WORD文档并删除指定文字",
              link: "word_del_str",
              img: require("@/assets/img/word_del_str.png"),
            },
            
          ],
        },
      ],
    };
  },
  methods: {
    to_work(link) {
      this.$emit('menu')

      if(link=="img_changtu"){
          this.$router.push("/upload/" + link);
      }
      else if(link=="img_to_pdf"){
         this.$router.push("/upload/" + link);

      }else if(link=="merge_pdf"){
         this.$router.push("/upload/" + link);

      }
      else{
         this.$router.push("/to_work/" + link);
      }


      


    },
  },
};
</script>

<style>
.wendang_menu {
  width: 50%;
  height: 100%;
  background: white;
  border-radius: 5px;
  box-shadow: 0 5px 21px -10px rgb(67 91 253 / 25%);
  padding: 38px 0px 26px 0px;
}
/* .menwendang_menu > .el-row {
  margin: 0px 200px;
  /* background: beige;
} */

.wendang_menu > .el-row > .el-col {
  /* background: red; */
  margin-left: 30px;
  width: 250px; 
}

.wendang_menu > * li {
  border-radius: 5px;
  padding: 0px 10px;
  height: 40px;
  line-height: 40px;
  width: 88%;
  list-style: none;
  cursor: pointer;
}

.wendang_menu > * h3 {
  font-size: 14px;
  line-height: 18px;
  color: #2b388f;
  font-weight: 600;
  padding-bottom: 5px;
  margin-left: 7px;
}

.wendang_menu > * a {
  text-decoration: none;
  color: #2b388f;
}

.wendang_menu > * a:visited {
  color: #2b388f;
}
.wendang_menu> * a:active {
  color: #fff;
}

.wendang_menu > * span {
  margin-left: 5px;
  font-size: 12px;
  font-weight: 500;
}

.el-col li:hover {
  font-weight: 600;
  background: #dae0ff;
  color: #0051ff;
}

.img_logo {
  height: 20px;
}
</style>