<template>
  <el-footer height="250px">
    <div class="nav1">
      <div class="nav">
        <ul>
          <li>
            <h2>Pdf格式转换</h2>
          </li>
          <li>文件格式互转</li>         
        </ul>
      </div>
      <div class="nav">
        <ul>
          <li>
            <h2>Pdf文件安全</h2>
          </li>
          <li>Pdf文件安全操作</li>          
        </ul>
      </div>
      <div class="nav">
        <ul>
          <li>
            <h2>转为Pdf格式</h2>
          </li>
          <li>其他格式转Pdf</li>          
        </ul>
      </div>
      <div class="nav">
        <ul>
          <li>
            <h2>图片特色转换</h2>
          </li>
          <li>图片特色转换功能</li>  
        </ul>
      </div>
    </div>

    <div class="one">
      
      <el-divider></el-divider>
      <div class="me">
        <a href="https://beian.miit.gov.cn/" target="_blank" class="me">备案号：</a>
        <a href="https://beian.miit.gov.cn/" target="_blank" class="me">桂ICP备2022011668号-1</a>

      </div>
    </div>
  </el-footer>
</template>

<script>
import logo from "@/assets/logo.png";
export default {
  name: "myfooter",
  data() {
    return {
      logo,
    };
  },
};
</script>

<style>
.el-footer {
  width:100%;
  position:relative;
  padding-left: 30px;
  background-color: rgb(48, 47, 47);
  color: rgb(248, 244, 244);
  font-size: 10px;
  font-weight: bold;
  font-family: "Noto Sans SC", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.footer_logo {
  margin: 50px 10px -20px 0;
  width: 40px;
  border-radius: 5px;
}
.me {
  text-align: center;  
  margin-bottom: 10px;
  color: rgb(248, 244, 244);
}
.one {
  /* border: 3px red solid; */
  width: 100%;
  height: 90px;
  margin: 150px 40px 0px 0px;
  align-items: center;
}
.one ul li {
  display: block;
}
.nav {
  /* border: 3px red solid; */
   width: 140px;
  height: 120px;
  line-height: 60px;
  font-size: 15px;
  float: left;
  margin: 30px 30px 10px 80px;
  font-family: 'Courier New', Courier, monospace;
}
.nav1 {
  width: 1000px;
  text-align: center;
  border-radius: 20px;
  margin: 0 auto;
  /* border: 1px solid red;改这里 */
}

.nav ul li {
  display: block;
}
</style>