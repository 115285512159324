<template>
  <el-container>
    <el-header height="72px">
      <el-row>
        <el-col :span="12">
          <el-col :span="4">
            <el-link :underline="false" href="/">
              <el-image
                style="width: 60px; height: 60px; margin-left: 100px"
                :src="url"
              ></el-image>
            </el-link>
          </el-col>

          <!-- <el-col :span="4"
            ><el-link :underline="false" href="/">{{this.website_name}}</el-link></el-col> -->

          <el-col :span="4">
            <div
              style="height: 100%; line-height: 70px"
              @mouseenter="hover"
              @mouseleave="leave_hover"
            >
              <el-link :underline="false"
                >所有工具<i class="el-icon-arrow-down el-icon--right"></i
              ></el-link>
            </div>
          </el-col>
          <el-col :span="4">
            <div
              style="height: 100%; line-height: 70px"
              @mouseenter="hover_2"
              @mouseleave="leave_hover_2"
            >
              <el-link :underline="false"
                >文档处理<i class="el-icon-arrow-down el-icon--right"></i
              ></el-link>
            </div>
          </el-col>
          <el-col :span="4">
            <div
              style="height: 100%; line-height: 70px"
              @mouseenter="hover_3"
              @mouseleave="leave_hover_3"
            >
              <el-link :underline="false"
                >特色转换<i class="el-icon-arrow-down el-icon--right"></i
              ></el-link>
            </div>
          </el-col>

          <!-- <el-col :span="4"
            ><el-link :underline="false" href="/#/upload/merge_pdf">PDF合并</el-link></el-col
          > -->

          <!-- <el-col :span="4"
            ><el-link :underline="false" href="/#/to_work/zip_pdf">PDF压缩</el-link></el-col
          > -->

          <!-- <el-col :span="4"
            ><el-link :underline="false" href="/#/to_work/pdf_to_word">转换</el-link></el-col
          > -->
        </el-col>

        <el-col :span="12">
          <el-col :span="4" :offset="18">
            <el-button v-if="true" @click="to_link('/login')">登录</el-button>
            <el-link v-else :underline="false" @click="to_link('/user')"
              ><el-avatar :src="avatar"></el-avatar
            ></el-link>
          </el-col>

          <el-col :span="4">
            <div>
              <el-menu class="el-menu-demo" mode="horizontal">
                <el-submenu index="1" :show-timeout="50">
                  <template slot="title"><i class="el-icon-menu"></i></template>
                  <el-menu-item @click="to_link('/')">返回首页</el-menu-item>
                  <el-menu-item @click="to_link('/user')">文库</el-menu-item>                  
                </el-submenu>
              </el-menu>
            </div>
          </el-col>
        </el-col>
      </el-row>
    </el-header>
    <div
      v-show="menu"
      @mouseenter="hover"
      @mouseleave="leave_hover"
      class="menu"
    >
      <Mymenu @menu="leave_hover"></Mymenu>
    </div>
    <div
      v-show="wendang_menu"
      @mouseenter="hover_2"
      @mouseleave="leave_hover_2"
      class="wendang_menu"
    >
      <Wendangmenu @menu="leave_hover_2"></Wendangmenu>
    </div>
    <div
      v-show="tese_menu"
      @mouseenter="hover_3"
      @mouseleave="leave_hover_3"
      class="tese_menu"
    >
      <Tesemenu @menu="leave_hover_3"></Tesemenu>
    </div>
  </el-container>
</template>

<script>
import "element-ui/lib/theme-chalk/index.css";
import logo from "@/assets/logo.png";
import Mymenu from "./menu.vue";
import Wendangmenu from "./new_menu.vue";
import Tesemenu from "./char_menu.vue";
import avatar from "@/assets/avatar.png";

import apiService from "@/api/service.js";
export default {
  name: "test",
  data() {
    return {
      url: logo,
      menu: false,
      wendang_menu: false,
      tese_menu: false,
      avatar,
    };
  },
  components: {
    Mymenu,
    Wendangmenu,
    Tesemenu,
  },
  methods: {
    hover() {
      this.menu = true;
    },
    leave_hover() {
      this.menu = false;
    },
    hover_2() {
      this.wendang_menu = true;
    },
    leave_hover_2() {
      this.wendang_menu = false;
    },
    hover_3() {
      this.tese_menu = true;
    },
    leave_hover_3() {
      this.tese_menu = false;
    },

    alert1() {
      alert("1");
    },
    login(evt) {
      this.$router.push("/#/login");
      alert("登录");
    },
    to_link(link){
      console.log(link)
      this.$router.push(link)
    }
  },
};
</script>

<style scoped>
.el-header {
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100%;
  background: #fff;
  color: #333;
  border-style: solid;
  border-width: 0px 0px 1px 0px;
  border-color: #c0bebe;
}
.el-row {
  /* background: wheat; */
  height: 72px;
  display: flex;
  justify-content: space-between;
}
.el-col {
  display: flex;
  /* align-content: center; */
  /* align-self: center; */
  align-items: center;
  height: 100%;
  width: 100%;
  justify-content: center;
  /* justify-items: center; */
  /* justify-self: center; */
  /* background: violet; */
}
.el-image {
  border-radius: 5px;
}
.menu {
  position: fixed;
  width: 100%;
  height: 500px;
  z-index: 1000;
  top: 72px;
}
.wendang_menu {
  position: fixed;
  margin-left: 28%;
  width: 300px;
  height: 300px;
  z-index: 100;
  top: 72px;
}
.tese_menu {
  position: fixed;
  margin-left: 38%;
  width: 300px;
  height: 270px;
  z-index: 100;
  top: 72px;
}
.el-button {
  min-width: 92px;
  border-color: #7a8af3;
  border-width: 2px;
  padding: 0 5px;
  color: #7a8af3;
  background-color: #fff;
  height: 36px;
  line-height: 36px;
}
.el-button:hover {
  background: #cae1f8;
}

.is-opened i {
  color: #409eff;
}

.el-menu.el-menu--horizontal {
  border-bottom: 0px;
}

/deep/ .el-submenu.is-active .el-submenu__title {
  border-bottom-color: #fff;
}

.el-menu-item:hover {
  background: #dae0ff;
}

.el-link {
  font-size: 14px;
  font-weight: bold;
}
</style>